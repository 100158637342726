import React from "react"
import h2p from "html2plaintext";
import Link from "gatsby-link";
import { graphql } from 'gatsby';
import TemplateWrapper from "../components/template-wrapper"
import showdown from 'showdown'

const BlogPostTemplate = props => {
    const { pageContext, data } = props
    const { link } = pageContext;
    const post = data.markdownPost;
    // const headerImages = data.headerImages;
    const converter = new showdown.Converter()
    const title = converter.makeHtml(post.frontmatter.title);
    const excerpt = converter.makeHtml((post.frontmatter.excerpt) ? post.frontmatter.excerpt : post.excerpt);
    const textExcerpt = h2p(excerpt);
    let counter = 0;

    const tagsString = (
      post.frontmatter.tags && post.frontmatter.tags.reduce( ( accum, tag ) => {
        if (accum === undefined)
          accum = '';
        if (tag === undefined) 
          return accum;

        return accum += ( counter++ > 0 ? `, ` : '' ) + tag;
      }, "")
    )

    const share = {
      url: 'https://cybersym.com' + link,
      title: h2p(title),
      excerpt: textExcerpt,
      tagsString: (tagsString) ? tagsString : '',
      realContent: true
    };
      
    return (
    <div className="palette--cst-site" style={{ textAlign: "center" }}> 
      {/* <TemplateWrapper headerImages = {headerImages} share={share} >     */}
      <TemplateWrapper share={share} >    
        <div className="blog-post" key={post.id}>
        <h2>
          <center><Link to={link} dangerouslySetInnerHTML={{__html: title}}/></center>
        </h2>
        <div 
          className="blog-inner" 
          style={{position: "relative",}}
          dangerouslySetInnerHTML={{__html: post.html}} 
        />  
        </div>
        <div style={{paddingBottom: "8.0rem"}} />
      </TemplateWrapper>
    </div>
  )  
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query mdBlogPostQuery( $link: String! ) {
    headerImages: allFile( 
      filter: { sourceInstanceName: { eq: "images" },
                name: { regex: "/cst/" } 
              }
    ) {
      edges {
        node {
          name
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 7.079646)
          }  
        }
      }
      totalCount
    }
    markdownPost: markdownRemark( frontmatter: { path: { eq: $link } }) {
      excerpt(pruneLength: 250)
      html
      id
      frontmatter {
        path
        title
        date(formatString: "MMMM DD, YYYY")
        tags
        categories
      }
    }
  }  
`;


